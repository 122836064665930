import axios from 'axios';
import config from '../config';

const apiClient = axios.create({
  baseURL: config.apiBaseUrl,
  timeout: config.apiTimeout,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Optionally add interceptors for request or response
apiClient.interceptors.request.use((apiConfig) => {
  // Modify config, e.g., attach tokens, logging, etc.
  return apiConfig;
});

export default apiClient;
